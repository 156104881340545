import { Backdrop, Box, Button, CircularProgress, TextField, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";
import { useCookiesLogin } from "../../config/cookies";

import { Formik } from "formik";
import { greenColor } from "../../config/colors";
import { HOST_SINGED } from "../../config/hosts";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import HandlerSerasaSocoreCPF from "../../components/SerasaScoreCPF";
import HandlerSerasaSocorePj from "../../components/SerasaScorePj";
import { handleSerasaScore } from "../redsimulation/createConsulting";


const isCPF = (value) => value.length == 11
const isCNPJ = (value) => value.length > 11;

export const formatCNPJ = (value) => {
  const onlyDigits = value.replace(/\D/g, ""); // Remove caracteres não numéricos

  if (onlyDigits.length > 11) {
    return onlyDigits.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    return onlyDigits.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
      "$1.$2.$3-$4"
    );
  }
};

const Consulta = ({ handleRefresh, username }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { cookies } = useCookiesLogin();
  const [quantityCoins, setQuantityCoins] = useState(0);
  const { bussines_id } = cookies?.BRAUM_TOKEN || {};

  const [loading, setLoading] = useState(true);
  const [loadingCoins, setLoadingCoins] = useState(true);

  

  useEffect(() => {

    console.log(bussines_id, "bussines_id")
    setLoadingCoins(true);
    fetch(`${HOST_SINGED}/reduto/consult/coins/${bussines_id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const requestStatus = res.json();


        if (res.status === 200) {
          setQuantityCoins(requestStatus.quantity)
        } else {
          setQuantityCoins(0);
        }

        
      }).finally(() => {
        setLoadingCoins(false);
      });
  }, []);

  return (
    <Box>
      {loadingCoins === true ? (
        <Box>
          <Typography
            variant="h5"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            Verificando saldo de consultas.
          </Typography>
        </Box>
      ) : (
        <Box>
          {quantityCoins !== 0 ? (
            handleRefresh === true ? (
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Nova Consulta
              </Button>
            ) : (
              <Button type="submit" color="secondary" variant="contained">
                Consultar cliente
              </Button>
            )
          ) : (
            <Typography
              variant="h5"
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ m: "0 0 5px 0" }}
            >
              Sem saldo de consultas.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};


const RedScore = () => {
  const { cookies } = useCookiesLogin();
  const theme = useTheme();
  const [document, setDocument] = useState("");
  const { id, username, bussines_id } = cookies.BRAUM_TOKEN;

  const userID = cookies.BRAUM_TOKEN.id;

  const [dataInfo, setDataInfo] = useState(false);
  const [dataAPI, setDataAPI] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [handleRefresh, setHandleRefresh] = useState(false);
  const [tokenSerasa, setTokenSerasa] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const regexCPF = /(\d{3})(\d{3})(\d{3})(\d{2})/;
  const regexCNPJ = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;


  const initialValues = {
    cpf: "",
    amount: "",
  };

  const verificationCPFCNPJAndAmount = (cpf) => {
    return cpf.replace(/\D/g, "").length === 0;
  };

  useEffect(() => {

  }, [isLoading, dataAPI, handleRefresh])

  useEffect(() => {
    console.log(cookies.BRAUM_TOKEN)
    async function getToken() {
      const authSerasa = await fetch(`${HOST_SINGED}/auth/serasa`, {
        method: "GET",
      });

      const returnToken = await authSerasa.json();
      setTokenSerasa(returnToken["token"]);
    }
    getToken();
  }, [dataAPI]);

  const valuesSchema = yup.object().shape({
    amount: yup.number().required("Valor é obrigatório"),
    cpf: yup.string().required("CNPJ é obrigatório"),
  });

  return (
    <Box p={5}>
      <Box mt={10}>
        <ToastContainer />
        <Header
          title={"RED SCORE"}
          subtitle="Informe o CPF ou CNPJ Rural e tenha acesso a pontuação do SCOREAGRO e probabilidade de inadimplência do seu cliente."
        />
        <Formik initialValues={initialValues} validationSchema={valuesSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form
              onSubmit={async (event) => {
                setIsLoading(true);
                handleSubmit(event);
                setHandleRefresh(true);

                if (verificationCPFCNPJAndAmount(values.cpf)) {
                  toast.info("CPF/CNPJ não pode estar vazio", {
                    theme: "dark",
                  });
                  setIsLoading(false);
                }

                const requestToken = await fetch(`${HOST_SINGED}/auth/serasa`, {
                  method: "GET",
                });

                const dataToken = await requestToken.json();


                // check user is already exists in database
                const response = await fetch(
                  `${HOST_SINGED}/client/${values.cpf.replace(/\D/g, "")}`,
                  {
                    method: "GET",
                  }
                );

                if (response.status == 200) {
                  // consulta ficha já existente
                  const dataClientAlreadyExists = await handleSerasaScore(
                    values.cpf.replace(/\D/g, ""),
                    dataToken["token"],
                    userID,
                    bussines_id
                  );

                  if (dataClientAlreadyExists.erro_saldo_fichas) {
                    toast.error("Ops! Saldo de fichas está zerado.", { theme: "dark" });
                    setIsLoading(false);
                    return;
                  }
                  setDataAPI(dataClientAlreadyExists);
                }

                if (response.status !== 200) {
                  toast.error("Ops! verifique a quantidade de fichas", { theme: "dark" });
                } else {
                  setDocument(values.cpf.replace(/\D/g, ""));
                  setHandleRefresh(true);
                  setIsLoading(false);
                }
              }}
            >
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              >
                <TextField
                  fullWidth
                  variant="filled"
                  label="CNPJ"
                  type="text"
                  color="secondary"
                  value={formatCNPJ(values.cpf)}
                  onChange={handleChange}
                  inputProps={{ maxLength: 18 }}
                  sx={{ gridColumn: "span 2" }}
                  name="cpf"
                  error={!!touched.cpf && !!errors.cpf}
                  helperText={touched.cpf && errors.cpf}
                />
                <Consulta handleRefresh={handleRefresh} username={username}></Consulta>
              </Box>
            </form>
          )}
        </Formik>


        {/* COMPONENTE RETORNA DIRETO O ID CADASTRADO NA BASE DE DADOS */}

        {console.log(isCPF(document) && dataAPI)}
         
        {isCPF(document) && (
          <HandlerSerasaSocoreCPF dataAPI={dataAPI}></HandlerSerasaSocoreCPF>
        )} 


        {/* COMPONENTE PARA ESPERAR O WEBHOOK BRAIN */}
        {console.log(isCNPJ(document))}
        {isCNPJ(document) && (
          <HandlerSerasaSocorePj dataAPI={dataAPI}></HandlerSerasaSocorePj>
        )}

        {
          isLoading && <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
            <CircularProgress color="inherit" sx={{ color: greenColor }} />
          </Backdrop>
        }

      </Box>
    </Box>
  );
};

export default RedScore;