import { useTheme } from "@emotion/react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookiesLogin } from "../../config/cookies";
import { tokens } from "../../theme";
import { InformacoesSerasa } from "../SerasaScoreCPF";

const regexCEP = /^(\d{5})(\d{3})$/;
const regexCPF = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
const regexPhone = /^(\d{2})(\d{1})?(\d{4})(\d{4})$/;

const HandlerSerasaSocorePj = ({ dataAPI }) => {
  const [officialName, setOfficialName] = useState("");
  const [tradeName, setTradeName] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [taxRegime, setTaxRegime] = useState("");
  const [legalNature, setLegalNature] = useState({
    Code: "",
    Activity: "",
  });
  const [activities, setActivities] = useState([]);
  const [taxIdStatus, setTaxIdStatus] = useState("");
  
  const [sizeCompany, setSizeCompany] = useState("");
  const [riskScreening, setRiskScreening] = useState("");
  const [operabilityIndicator, setOperabilityIndicator] = useState("");
  const [taxIdOrigin, setTaxIdOrigin] = useState("SERASA EXPERIAN");

  const [score, setScore] = useState("");
  const [eventProbability, setEventProbability] = useState("");
  const { cookies } = useCookiesLogin();
  const { id, bussines_id } = cookies.BRAUM_TOKEN;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const navigateToDashboard = () => navigate("/app");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const data = {
    _id: "66ad2ae1478c1c1f578071ca",
    inputs: {
      document: "08196949000110",
      datasets: ["BASIC_INFO", "AGRO_SCORE_CNPJ"],
    },
    datasets: [
      {
        type: "BASIC_INFO",
        status: "COMPLETED",
        results: [
          {
            basicData: {
              officialName: "GUILHERME JOSE REHDER",
              tradeName: "",
              taxIdNumber: "08196949000110",
              taxRegime: "Produtor Rural (Pessoa Física)",
              legalNature: {
                Code: "4120",
                Activity: "Produtor Rural (Pessoa Física)",
              },
              taxIdStatus: "ATIVA",
              taxIdStatusDate: "2006-08-03T00:00:00.000Z",
              activities: [
                {
                  Code: "0134200",
                  Activity: "Cultivo de café",
                },
                {
                  Code: "0111302",
                  Activity: "Cultivo de milho",
                },
              ],
              taxIdStatusRegistrationDate: "2006-08-03T00:00:00.000Z",
              capital: "0",
              companySize: "MÉDIA",
              riskScreening: "RISCO MODERADO",
              operabilityIndicator: "MEDIO",
            },
            phones: [
              "(19) 997987608",
              "(19) 36331028",
              "(19) 997751090",
              "(19) 996010323",
              "(19) 996685544",
            ],
            emails: [],
            addresses: [
              {
                type: "",
                addressMain: "FAZE BARREIRO REHDER",
                number: "S/N",
                complement: "RURAL",
                neighborhood: "ZONA RURAL",
                zipcode: "13860000",
                city: "AGUAI",
                uf: "SP",
                ibgeCode: "3500303",
              },
              {
                type: "FAZ",
                addressMain: "BARREIRO REHDER",
                number: "SN",
                complement: "RURAL",
                neighborhood: "ZONA RURAL",
                zipcode: "13860000",
                city: "AGUAI",
                uf: "SP",
                ibgeCode: "3500303",
              },
              {
                type: "FAZ",
                addressMain: "BARREIRO REHDER",
                number: "SN",
                complement: "N RURAL S",
                neighborhood: "ZONA RURAL",
                zipcode: "13860000",
                city: "AGUAI",
                uf: "SP",
                ibgeCode: "3500303",
              },
              {
                type: "",
                addressMain: "AZ BARREIR RURAL",
                number: "",
                complement: "",
                neighborhood: "ZONA RURAL",
                zipcode: "13860000",
                city: "AGUAI",
                uf: "SP",
                ibgeCode: "3500303",
              },
              {
                type: "FAZ",
                addressMain: "BARREIRO REHDER",
                number: "SN",
                complement: "RURAL",
                neighborhood: "ZONA RURAL",
                zipcode: "00000000",
                city: "",
                uf: "",
                ibgeCode: "",
              },
              {
                type: "PC",
                addressMain: "CEL JOAQUIM JOSE",
                number: "176",
                complement: "",
                neighborhood: "CENTRO",
                zipcode: "13870120",
                city: "SAO JOAO DA BOA VISTA",
                uf: "SP",
                ibgeCode: "3549102",
              },
            ],
          },
        ],
      },
      {
        type: "AGRO_SCORE_CNPJ",
        status: "COMPLETED",
        results: [
          {
            id: "160962",
            document: "h5cLEWo+IQW4+eUGtXnrdG3HndqSZdewNv51wWvvAV4=",
            requester: "34849124000168",
            score: 899,
            event_probability: 0.00508,
            reference_date: "2024-07-28",
            created_at: "2024-08-02T18:52:18.582Z",
            updated_at: "2024-08-02T18:52:18.582Z",
          },
        ],
      },
    ],
  };


  console.log(dataAPI, "dataapi")
  let basicInfo = dataAPI.data.datasets.find(
    (basicInfo) => basicInfo.type == "BASIC_INFO"
  );
  let serasaScore = dataAPI.data.datasets.find(
    (basicInfo) => basicInfo.type == "AGRO_SCORE_CNPJ"
  );

  let serasa = dataAPI.data.datasets.find(
    (basicInfo) => basicInfo.type == "SERASA"
  );

  console.log(basicInfo, "basic info finded")

  console.log(serasaScore, "serasaScore finded")

  console.log(serasa, "serasa finded")


  // já existe a consulta
  useEffect(() => {
    console.log(basicInfo.results[0]);
    console.log(serasaScore.results[0]);
    setOfficialName(basicInfo.results[0].basicData.officialName);
    setTaxIdNumber(basicInfo.results[0].basicData.taxIdNumber);
    setActivities(basicInfo.results[0].basicData.activities);
    setTaxRegime(basicInfo.results[0].basicData.taxRegime);
    setLegalNature(basicInfo.results[0].basicData.legalNature);
    setTaxIdStatus(basicInfo.results[0].basicData.taxIdStatus);
    setSizeCompany(basicInfo.results[0].basicData.companySize);
    setOperabilityIndicator(
      basicInfo.results[0].basicData.operabilityIndicator
    );
    setRiskScreening(basicInfo.results[0].basicData.riskScreening);

    setScore(serasaScore.results[0].score);
    setEventProbability(serasaScore.results[0].event_probability);
  }, []);

  return (
    <>
      <ToastContainer />
      <div
        className=""
        style={{
          marginTop: 50,
        }}
      >
        <Typography variant="h3" color={colors.grey[500]} gutterBottom>
          Dados informativos da empresa.
        </Typography>
        <Grid container spacing={4} marginBottom={3}>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Documento
            </Typography>
            <Typography fontSize={22}>
              {taxIdNumber.replace(regexCNPJ, "$1.$2.$3.$4/$5")}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Nome
            </Typography>
            <Typography fontSize={22}>{officialName}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Regime
            </Typography>
            <Typography fontSize={22}>{taxRegime}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Origem Dados
            </Typography>
            <Typography fontSize={22}>{taxIdOrigin}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              CNAES
            </Typography>
            <Typography fontSize={22}>
              Atividade {legalNature.Activity}
            </Typography>
            <Typography fontSize={22}>Código {legalNature.Code}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Triagem de Risco
            </Typography>
            <Typography fontSize={16} color={colors.grey[500]}>
              Detecção precoce de danos ao cliente para prestação de cuidados
              seguros, eficazes e adequados.
            </Typography>
            <Typography fontSize={22}>{riskScreening}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Operabilidade indicador
            </Typography>
            <Typography fontSize={16} color={colors.grey[500]}>
              Indicadores de retardo incluem taxa de disponibilidade e tempo de
              restauração, indicadores antecipados incluem frequência de dias de
              caos e tempo de resposta a incidentes
            </Typography>

            <Typography fontSize={22}>{operabilityIndicator}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Porte da empresa
            </Typography>
            <Typography fontSize={22}>{sizeCompany}</Typography>
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Triagem de risco
            </Typography>
            <Typography fontSize={22}>{operabilityIndicator}</Typography>
          </Grid>
        </Grid>
        {activities.length != 0 ? (
          <Grid sx={{ marginY: 3 }} grid-xs-7>
            <Typography variant="h3" color={colors.grey[500]} gutterBottom>
              Atividades CNPJ.
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Atividade</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {activities.map((data) => (
                    <TableRow>
                      <TableCell>{data.Code}</TableCell>
                      <TableCell>{data.Activity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null}
      </div>
      <div
        className=""
        style={{
          marginTop: 30,
        }}
      >
        <Typography variant="h3" color={colors.grey[500]} gutterBottom>
          Informações para análise de crédito
        </Typography>
        <Grid container spacing={4} marginBottom={3} marginTop={3}>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Score
            </Typography>
            <Typography
              color={
                score < 333 ? "#ff5050" : score < 666 ? "#fff28b" : "secondary"
              }
              fontSize={22}
            >
              {score} de 1000
            </Typography>

            <GaugeChart
              id="gauge-chart2"
              nrOfLevels={20}
              percent={score / 1000}
              textColor="#f1f1f1"
              arcWidth={0.2}
              colors={["#FF0000", "#00FF00"]}
              formatTextValue={(value) => score}
              style={{
                fontWeight: 600,
              }}
            />
          </Grid>
          <Grid item xs={4} sx={{ marginY: 3 }}>
            <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
              Propabilidade de Inadimplência
            </Typography>
            <Typography fontSize={22}>
              {parseFloat(eventProbability * 100).toFixed(2)} %
            </Typography>
          </Grid>
        </Grid>
        <ScoreDescription />

        {/* SERASA Section */}
        <InformacoesSerasa serasaData={serasa}></InformacoesSerasa>
      </div>
    </>
  );
};

export const ScoreDescription = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* Imagem do Serasa Experian */}
      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: 3 }}
      ></Box>
      <Typography
        variant="h4"
        align="left"
        gutterBottom
        color={colors.greenAccent["500"]}
        sx={{ fontSize: "2rem" }}
      >
        Entenda o RedScore
      </Typography>
      <Typography
        variant="h4"
        align="left"
        color="textSecondary"
        paragraph
        sx={{ fontSize: "1.25rem" }}
      >
        O RedScore é uma pontuação que vai de 0 a 1000 e reflete a probabilidade
        de um consumidor pagar suas contas em dia.
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {/* Quadradinho Vermelho */}
        {/* <Grid item xs={12} sm={1}>
          <Box
            sx={{
              width: 24,
              height: 24,
              backgroundColor: "error.main",
              margin: "0 auto",
              borderRadius: 1,
            }}
          />
        </Grid> */}

        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.5rem" }}>
              Score baixo
            </Typography>
            <Typography
              variant="h4"
              color="error"
              gutterBottom
              sx={{ fontSize: "2rem" }}
            >
              0 a 300
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: "1.1rem" }}
            >
              Alto risco de inadimplência. Dificuldade em conseguir crédito.
            </Typography>
          </Paper>
        </Grid>

        {/* Quadradinho Amarelo */}
        {/* <Grid item xs={12} sm={1}>
          <Box
            sx={{
              width: 24,
              height: 24,
              backgroundColor: "warning.main",
              margin: "0 auto",
              borderRadius: 1,
            }}
          />
        </Grid> */}

        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.5rem" }}>
              Score médio
            </Typography>
            <Typography
              variant="h4"
              color="warning.main"
              gutterBottom
              sx={{ fontSize: "2rem" }}
            >
              301 a 700
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: "1.1rem" }}
            >
              Risco moderado. Chance razoável de conseguir crédito, mas com
              condições restritas.
            </Typography>
          </Paper>
        </Grid>

        {/* Quadradinho Verde */}
        {/* <Grid item xs={12} sm={1}>
          <Box
            sx={{
              width: 24,
              height: 24,
              backgroundColor: "success.main",
              margin: "0 auto",
              borderRadius: 1,
            }}
          />
        </Grid> */}

        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.5rem" }}>
              Score alto
            </Typography>
            <Typography
              variant="h4"
              color="success.main"
              gutterBottom
              sx={{ fontSize: "2rem" }}
            >
              701 a 1000
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: "1.1rem" }}
            >
              Baixo risco de inadimplência. Facilidade em obter crédito com boas
              condições.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        align="center"
        color="textSecondary"
        paragraph
        sx={{ marginTop: 3, fontSize: "1.25rem" }}
      >
        Score de crédito AGRO, focado nas movimentações e segmento do agronegócio, utilizando o Serasa Experian como fonte de dados.
      </Typography>
    </Box>
  );
};
export default HandlerSerasaSocorePj;
